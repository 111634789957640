import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import ConfirmBox from "../../components/confirmBox";
import { SuccessMsg, ErrMsg } from "../../components/message";
import { DefDatePicker } from "../../components/datepicker";
import Icon, {
    ChevronLeft,
    CaretLeft,
    CaretRight,
    SortUp,
    SortDown
} from "../../components/icon";

import { CurrentUserContext } from "../../providers/auth";
import Services, {
    isUnauthorizedError,
    isForbiddenError
} from "../../services";
import {
    formatDateStr,
    formatDate,
    getDiffMinutes,
    addDate,
    momentTz,
    timeFormats
} from "../../utils/time";
import {
    KEY_DATA_VALIDATE_EDITING,
    isEditingValidation,
    unlockAndAction,
    confirmCloseWindow,
    setLockedLamppost,
    getLockedLamppost
} from "../../utils/prompt-validate";
import { lamppostDisplayName } from "../../utils/lamppost";
import Links from "../../utils/links";
import UserUtil from "../../utils/user";

const DataValidatePage = () => {
    const [checkCount, setCheckCount] = useState(0);

    let postSessionRefreshed = null;

    const refreshSessionCallback = () => {
        if (postSessionRefreshed) {
            postSessionRefreshed();
        }
    };

    const setRefreshSessionCallback = (callback) => {
        postSessionRefreshed = callback;
    };

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            className="p-4"
            link={Links.Validation}
            seoTitle="Data Validation"
            permissionValidator={UserUtil.hasDataOperatorPrivileges}
            refreshSessionCallback={refreshSessionCallback}
            forceCheckSessionCount={checkCount}
        >
            <DataValidate
                setRefreshSessionCallback={setRefreshSessionCallback}
                forceCheckSession={forceCheckSession}
            />
        </Layout>
    );
};

export default DataValidatePage;

const DataValidate = ({ setRefreshSessionCallback, forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const [dateQp, setDateQp] = useQueryParam("date", StringParam);
    const [lamppostIdQp, setLamppostIdQp] = useQueryParam(
        "lamppostId",
        StringParam
    );
    const [valMonthQp, setValMonthQp] = useQueryParam("valMonth", StringParam);
    const [valLpQp, setValLpQp] = useQueryParam("valLp", StringParam);
    const [viewOnlyQp, setViewOnlyQp] = useQueryParam("viewOnly", StringParam);

    const polluInputBox = React.createRef();
    const flagSelect = React.createRef();

    const [user, setUser] = useState(null);
    const [lampposts, setLampposts] = useState([]);
    const [curLamppost, setCurLamppost] = useState("");
    const [interims, setInterims] = useState([]);
    const [editedInterims, setEditedInterims] = useState({});
    const [validatedFields, setValidatedFields] = useState({});
    const [lastValidated, setLastValidated] = useState({ by: "", at: null });
    const [confirmUnsave, setConfirmUnsave] = useState(false);
    const [prevOption, setPrevOption] = useState({
        lamppostId: null,
        day: null
    });
    const [confirmUnsaveReturn, setConfirmUnsaveReturn] = useState(false);
    const [sortBy, setSortBy] = useState({
        field: TIME,
        ascending: true
    });

    const [displayDate, setDisplayDate] = useState(new Date());
    const [day, setDay] = useState(new Date());
    const [unlockErr, setUnlockErr] = useState("");

    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [saveState, setSaveState] = useState({ error: "", ok: false });

    const [updating, setUpdating] = useState(false);
    const [updateErr, setUpdateErr] = useState("");

    // States for selecting and updating pollutant or pollutant flag records: selectStart, selectedIdx
    const [selectStart, setSelectStart] = useState({
        state: false,
        polluOrFlag: null
    });
    const [selectedIdx, setSelectedIdx] = useState({
        min: null,
        max: null,
        count: 0
    });

    const [polluInput, setPolluInput] = useState("");
    const [polluInputErr, setPolluInputErr] = useState("");
    const [flagInput, setFlagInput] = useState(POLLU_FLAGS[0].value);

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err) || isForbiddenError(err)) {
            forceCheckSession();
        }
    };

    // extend existing lamppost lock after user session refreshed
    const refreshLamppostLock = () => {
        const lockedLamppostId = getLockedLamppost();
        if (lockedLamppostId) {
            lamppostService
                .lockLamppostMetrics(lockedLamppostId, toYYYYMMDD(displayDate))
                .catch((err) => handleAuthError(err));
        }
    };
    setRefreshSessionCallback(refreshLamppostLock);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setLockedLamppost(null);
        setLoading(true);
        currentUser
            .getUser()
            .then((u) => {
                setUser(u);
                return lamppostService.getLampposts();
            })
            .then((d) => {
                const ls = d.lampposts || [];
                setLampposts(ls);
                if (ls.length > 0) {
                    let lamppostId = ls[0].lamppost_id;
                    if (lamppostIdQp) {
                        if (ls.find((l) => l.lamppost_id === lamppostIdQp)) {
                            lamppostId = lamppostIdQp;
                        }
                    } else {
                        setLamppostIdQp(lamppostId);
                    }
                    setCurLamppost(lamppostId);
                    let curDay = day;
                    if (dateQp) {
                        const m = momentTz(dateQp, timeFormats.YYYYMMDD);
                        if (m.isValid()) {
                            curDay = m.toDate();
                            setDay(curDay);
                        }
                    } else {
                        setDateQp(toYYYYMMDD(curDay));
                    }
                    getInterimDataPromptUnsave(lamppostId, curDay);
                }
            })
            .catch((err) => {
                handleAuthError(err);
                setLampposts([]);
                setLoading(false);
            });

        window.onbeforeunload = function () {
            return confirmCloseWindow(
                window.localStorage.getItem(KEY_DATA_VALIDATE_EDITING) ===
                    "true"
            );
        };

        return () => {
            window.localStorage.removeItem(KEY_DATA_VALIDATE_EDITING);
        };
    }, [currentUser]);

    const canEdit = () => !(canReject() || viewOnlyQp === "1");

    const canReject = () =>
        formatDate(day, timeFormats.YYYYMM) === valMonthQp &&
        curLamppost === valLpQp &&
        UserUtil.isSysAdmin(user && user.profile);

    const onRejectErr = (err) => {
        handleAuthError(err);
        const errMsg = err.message || "Error occured.";
        setValMonthQp("");
        setValLpQp("");
        setViewOnlyQp("0");
        setSaveState({
            error: "Cannot reject dataset: " + errMsg,
            ok: false
        });
    };

    const editedTotal = () => Object.keys(editedInterims).length;

    const onChangeLamppost = (e) => {
        const newLamppostId = e.target.value;
        setPrevOption({ lamppostId: curLamppost, day: day });
        setCurLamppost(newLamppostId);
        setLamppostIdQp(newLamppostId);
        getInterimDataPromptUnsave(newLamppostId, day);
    };

    const onChangeFlagInput = (e) => {
        const val = e.target.value;
        setFlagInput(val ? val : null);
    };

    const getInterimDataPromptUnsave = (lamppostId, day) => {
        if (editedTotal() !== 0) {
            setConfirmUnsave(true);
            return;
        }
        getInterimData(lamppostId, day);
    };

    const getInterimData = (lamppostId, day) => {
        setUnlockErr("");
        setConfirmUnsave(false);
        const lockedLamppostId = getLockedLamppost();
        if (lockedLamppostId) {
            lamppostService
                .unlockLamppostMetrics(lockedLamppostId)
                .then(() => {
                    setLockedLamppost(null);
                    setUnlockErr("");
                    _getInterimData(lamppostId, day);
                })
                .catch((err) => {
                    handleAuthError(err);
                    setUnlockErr("Cannot unlock editing data.");
                });
        } else {
            _getInterimData(lamppostId, day);
        }
    };

    const _getInterimData = (lamppostId, day) => {
        setLoading(true);
        setCacheEditing({});
        setValidatedFields({});
        setSaveState({ error: "", ok: false });
        setUpdateErr("");
        lamppostService
            .getValidateData(lamppostId, toYYYYMMDD(day))
            .then((ds) => setData(ds))
            .catch((err) => {
                handleAuthError(err);
                setInterims([]);
            })
            .finally(() => {
                setSortBy({
                    field: TIME,
                    ascending: true
                });
                setLoading(false);
                setEditedInterims({});
                setDisplayDate(day);
                resetSelectData();
            });
    };

    const setData = (ds) => {
        setInterims(ds.interim);
        setValidatedFields(
            ds.validated_fields.reduce((vfields, d) => {
                vfields[d[TIME]] = d.fields.reduce((dict, f) => {
                    dict[f] = true;
                    return dict;
                }, {});
                return vfields;
            }, {})
        );
        setLastValidated({
            by: ds.last_validated_by,
            at: ds.last_validated_at
        });
    };

    const setDayCheckReject = (d) => {
        setDay(d);
        setDateQp(toYYYYMMDD(d));
    };

    const onChangeDay = (d) => {
        if (!d || toYYYYMMDD(d) === toYYYYMMDD(day)) {
            return;
        }
        setPrevOption({ lamppostId: curLamppost, day: day });
        setDayCheckReject(d);
        getInterimDataPromptUnsave(curLamppost, d);
    };

    const saveInterim = () => {
        let records = [];
        const fields = [
            NO_CONC,
            NO_FLAG,
            NO2_CONC,
            NO2_FLAG,
            PM25_CONC,
            PM25_FLAG
        ];
        Object.keys(editedInterims).forEach((ei) => {
            const edited = editedInterims[ei].edited;
            const data = editedInterims[ei].data;
            let vs = [];
            fields.forEach((field) => {
                if (edited[field] === true) {
                    if (isPollutant(field)) {
                        vs.push({
                            field,
                            conc:
                                data[field] !== "" ? Number(data[field]) : null
                        });
                    } else if (isPolluFlag(field)) {
                        vs.push({
                            field,
                            flag: data[field] ? data[field] : null
                        });
                    }
                }
            });
            if (vs.length !== 0) {
                records.push({
                    [TIME]: data[TIME],
                    values: vs
                });
            }
        });
        lamppostService
            .saveInterimData(curLamppost, toYYYYMMDD(day), records)
            .then(() =>
                lamppostService.getValidateData(curLamppost, toYYYYMMDD(day))
            )
            .then((ds) => {
                setData(ds);
                setSortBy({
                    field: TIME,
                    ascending: true
                });
                resetSelectData();
                setSaveState({ error: "", ok: true });
                setLockedLamppost(null);
                setEditedInterims({});
                setCacheEditing({});
                setConfirm(false);
            })
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
                setConfirm(false);
                setSaveState({ error: "Cannot save records.", ok: false });
            });
    };

    const updateSelectedInterim = (idx, polluOrFlag) => {
        if (!selectStart.state) {
            return;
        }
        if (selectStart.polluOrFlag !== polluOrFlag) {
            return;
        }
        _updateSelectInterim(idx);
    };

    const setSelectedMinMax = (idx) => {
        if (selectedIdx.count === 0) {
            setSelectedIdx({ min: idx, max: idx, count: 1 });
            return;
        }
        if (idx < selectedIdx.min || idx > selectedIdx.max) {
            const min = Math.min(selectedIdx.min, idx);
            const max = Math.max(selectedIdx.max, idx);
            setSelectedIdx({ min, max, count: max - min + 1 });
        }
    };

    const _updateSelectInterim = (idx) => {
        setSelectedMinMax(idx);
    };

    const reverseSelectedMinMax = () => {
        if (selectedIdx.count === 0) {
            return;
        }
        let newSelectedIdx = Object.assign({}, selectedIdx);
        newSelectedIdx.min = interims.length - 1 - selectedIdx.max;
        newSelectedIdx.max = interims.length - 1 - selectedIdx.min;
        setSelectedIdx(newSelectedIdx);
    };

    const onStopSelectInterim = (polluOrFlag) => {
        if (
            selectStart.polluOrFlag &&
            selectStart.polluOrFlag !== polluOrFlag
        ) {
            setSelectStart({
                state: false,
                polluOrFlag: selectStart.polluOrFlag
            });
            return;
        }
        setSelectStart({ state: false, polluOrFlag: polluOrFlag });
        if (isPollutant(polluOrFlag)) {
            polluInputBox.current.focus();
        } else if (isPolluFlag(polluOrFlag)) {
            flagSelect.current.focus();
        }
    };

    const onStartSelectInterim = (idx, polluOrFlag) => {
        if (selectStart.state) {
            return;
        }
        if (
            selectStart.polluOrFlag === null ||
            selectStart.polluOrFlag === polluOrFlag
        ) {
            setSelectStart({ state: true, polluOrFlag: polluOrFlag });
            _updateSelectInterim(idx, polluOrFlag);
            setPolluInputErr("");
            setSaveState({ error: "", ok: false });
        } else {
            setSelectStart({ state: true, polluOrFlag: polluOrFlag });
            setSelectedIdx({ min: idx, max: idx, count: 1 });
            setPolluInput("");
            setPolluInputErr("");
            setSaveState({ error: "", ok: false });
        }
    };

    const onChangeDataInput = (e) => {
        setPolluInput(e.target.value);
    };

    const updateEditInterim = (editInterim, interim, polluOrFlag, newValue) => {
        if (!editInterim) {
            editInterim = {
                data: Object.assign({}, interim),
                edited: {
                    no_conc: false,
                    no_flag: false,
                    no2_conc: false,
                    no2_flag: false,
                    pm25_conc: false,
                    pm25_flag: false
                }
            };
        }
        editInterim.data[polluOrFlag] = newValue;
        editInterim.edited[polluOrFlag] = true;
        return editInterim;
    };

    const lockAndUpdateSelected = (updateFunc) => {
        setUnlockErr("");
        if (getLockedLamppost()) {
            updateFunc();
            return;
        }
        setUpdating(true);
        lamppostService
            .lockLamppostMetrics(curLamppost, toYYYYMMDD(day))
            .then(() => {
                setLockedLamppost(curLamppost);
                updateFunc();
            })
            .catch((err) => {
                handleAuthError(err);
                setUpdateErr(
                    err.message || "Cannot lock the data for editing."
                );
            })
            .finally(() => setUpdating(false));
    };

    const updateSelectedData = () => lockAndUpdateSelected(_updateSelectedData);

    const _updateSelectedData = () => {
        setPolluInputErr("");
        const curPollutant = selectStart.polluOrFlag;
        if (!curPollutant) {
            return;
        }
        let newValues = polluInput.split(/\n/);
        const selectedLen = selectedIdx.count;
        if (newValues.length === 1 || newValues.length === selectedLen) {
            for (let i = 0; i < newValues.length; i++) {
                const curVal = newValues[i].trim();
                if (curVal && isNaN(Number(curVal))) {
                    setPolluInputErr(
                        "Only numbers are allowed or empty line for missing entry."
                    );
                    return;
                }
            }
        }
        if (newValues.length === 1) {
            newValues = Array.apply(null, Array(selectedLen)).map(
                () => newValues[0]
            );
        }
        if (newValues.length === selectedLen) {
            if (selectedIdx.count === 0) {
                return;
            }
            let curIdx = 0;
            let newEditedInterims = Object.assign({}, editedInterims);
            for (let idx = selectedIdx.min; idx <= selectedIdx.max; idx++) {
                const time = interims[idx][TIME];
                newEditedInterims[time] = updateEditInterim(
                    newEditedInterims[time],
                    interims[idx],
                    curPollutant,
                    newValues[curIdx].trim()
                );
                curIdx++;
            }
            setEditedInterims(newEditedInterims);
            setCacheEditing(newEditedInterims);
            resetSelectData();
            return;
        }
        setPolluInputErr(
            "No. of input records does not match no. of selected records."
        );
    };

    const updateSelectedFlag = () => lockAndUpdateSelected(_updateSelectedFlag);
    const _updateSelectedFlag = () => {
        const curFlag = selectStart.polluOrFlag;
        if (!curFlag) {
            return;
        }
        if (selectedIdx.count === 0) {
            return;
        }
        const curPollu = polluForFlag(curFlag);
        let newEditedInterims = Object.assign({}, editedInterims);
        for (let idx = selectedIdx.min; idx <= selectedIdx.max; idx++) {
            const time = interims[idx][TIME];
            newEditedInterims[time] = updateEditInterim(
                newEditedInterims[time],
                interims[idx],
                curFlag,
                flagInput
            );
            const { value, ok } = setPolluForFlag(flagInput);
            if (ok) {
                newEditedInterims[time].data[curPollu] = value;
                newEditedInterims[time].edited[curPollu] = true;
            }
        }
        setEditedInterims(newEditedInterims);
        setCacheEditing(newEditedInterims);
        resetSelectData();
    };

    const resetSelectData = () => {
        setSelectStart({ state: false, polluOrFlag: null });
        setSelectedIdx({ min: null, max: null, count: 0 });
        setPolluInput("");
        setPolluInputErr("");
    };

    const setCacheEditing = (newEditedInterims = {}) => {
        window.localStorage.setItem(
            KEY_DATA_VALIDATE_EDITING,
            Object.keys(newEditedInterims).length !== 0
        );
    };

    const restoreSelectData = () => {
        const curPolluOrFlag = selectStart.polluOrFlag;
        if (!curPolluOrFlag) {
            return;
        }
        if (selectedIdx.count === 0) {
            return;
        }
        let newEditedInterims = Object.assign({}, editedInterims);
        for (let idx = selectedIdx.min; idx <= selectedIdx.max; idx++) {
            const time = interims[idx][TIME];
            const ei = newEditedInterims[time];
            if (!ei) {
                continue;
            }
            ei.edited[curPolluOrFlag] = false;
            const edited = ei.edited;
            if (
                !(
                    edited.no_conc ||
                    edited.no_flag ||
                    edited.no2_conc ||
                    edited.no2_flag ||
                    edited.pm25_conc ||
                    edited.pm25_flag
                )
            ) {
                delete newEditedInterims[time];
            }
        }
        setEditedInterims(newEditedInterims);
        resetSelectData();
    };

    const selectedTimesInfos = () => {
        const polluOrFlag = selectStart.polluOrFlag;
        if (!polluOrFlag) {
            return null;
        }
        if (selectedIdx.count === 0) {
            return null;
        }
        if (sortBy.field !== TIME) {
            return null;
        }
        return (
            <div className="mb-2">
                <div>
                    Time:{" "}
                    {getDiffHourMinuteText(
                        interims[selectedIdx.min][TIME],
                        momentTz(displayDate, timeFormats.YYYYMMDD).toDate()
                    )}{" "}
                    to{" "}
                    {getDiffHourMinuteText(
                        interims[selectedIdx.max][TIME],
                        momentTz(displayDate, timeFormats.YYYYMMDD).toDate()
                    )}
                </div>
            </div>
        );
    };

    const numOfPolluInputs = () => polluInput.split(/\n/).length;

    const validNumOfPolluInputs = () =>
        numOfPolluInputs() === 1 || selectedIdx.count === numOfPolluInputs();

    const editPollutant = () => (
        <div className={!isPollutant(selectStart.polluOrFlag) ? "hidden" : ""}>
            <div className="text-base mb-2">
                Edit {POLLU_NAMES[selectStart.polluOrFlag]}
            </div>
            {selectedTimesInfos()}
            <div className="mt-2 w-full">
                <textarea
                    key="data-input-box"
                    className="w-full textarea"
                    ref={polluInputBox}
                    value={polluInput}
                    onChange={onChangeDataInput}
                    placeholder="Input numbers: Each number in one line (empty line for missing entry)"
                    rows="10"
                ></textarea>
            </div>
            <div
                className={
                    "mt-2 text-xs " +
                    (validNumOfPolluInputs() ? "green" : "text-gray-700")
                }
            >
                No. of selected records: {selectedIdx.count}
                <br />
                No. of input records: {numOfPolluInputs()}
            </div>
            <div className="text-err">{polluInputErr}</div>
            <div className={"text-center mt-4" + (updating ? " hidden" : "")}>
                <div>
                    <button
                        className="btn-primary w-4/5 mx-auto"
                        onClick={updateSelectedData}
                        disabled={!validNumOfPolluInputs()}
                    >
                        Update
                    </button>
                </div>
                <div>
                    <button
                        className="mt-2 btn-secondary w-4/5 mx-auto"
                        onClick={resetSelectData}
                        title="Unselect all records"
                    >
                        Unselect
                    </button>
                </div>
                <div>
                    <button
                        className="mt-2 btn-secondary w-4/5 mx-auto"
                        title="Restore to original values"
                        onClick={restoreSelectData}
                    >
                        Restore
                    </button>
                </div>
            </div>
        </div>
    );

    const editPollutFlag = () => (
        <div className={!isPolluFlag(selectStart.polluOrFlag) ? "hidden" : ""}>
            <div className="text-base mb-2">
                Edit {POLLU_NAMES[selectStart.polluOrFlag]}
            </div>
            {selectedTimesInfos()}
            <div className="mt-2 w-full">
                <select
                    className="input"
                    type="text"
                    ref={flagSelect}
                    value={flagInput}
                    onChange={onChangeFlagInput}
                >
                    {POLLU_FLAGS.map((f, idx) => {
                        return (
                            <option key={idx} value={f.value}>
                                {(f.value ? f.value + ". " : "") + f.desc}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className={"text-center mt-4" + (updating ? " hidden" : "")}>
                <div>
                    <button
                        className="btn-primary w-4/5 mx-auto"
                        onClick={updateSelectedFlag}
                    >
                        Update
                    </button>
                </div>
                <div>
                    <button
                        className="mt-2 btn-secondary w-4/5 mx-auto"
                        onClick={resetSelectData}
                        title="Unselect all records"
                    >
                        Unselect
                    </button>
                </div>
                <div>
                    <button
                        className="mt-2 btn-secondary w-4/5 mx-auto"
                        title="Restore to original values"
                        onClick={restoreSelectData}
                    >
                        Restore
                    </button>
                </div>
            </div>
        </div>
    );

    const polluOrFlagTD = (idx, data, polluOrFlag) => {
        const time = data[TIME];
        const ei = editedInterims[time];
        const edited = ei && ei.edited[polluOrFlag];
        const validated = validatedFields[time] || {};
        const value = data[polluOrFlag];
        const origValue = value !== null && value !== "" ? value : null;

        return (
            <td
                className={
                    selectStart.polluOrFlag === polluOrFlag &&
                    selectedIdx.count &&
                    selectedIdx.min <= idx &&
                    idx <= selectedIdx.max
                        ? "bg-blue-200"
                        : edited
                        ? "bg-yellow-200"
                        : validated[polluOrFlag] === true
                        ? "bg-green-200"
                        : ""
                }
                style={{ cursor: canEdit() ? "crosshair" : "" }}
                onMouseUp={() => canEdit() && onStopSelectInterim(polluOrFlag)}
                onMouseDown={() =>
                    canEdit() && onStartSelectInterim(idx, polluOrFlag)
                }
                onMouseOver={() =>
                    canEdit() && updateSelectedInterim(idx, polluOrFlag)
                }
            >
                {edited ? (
                    <div
                        title={origValue ? "Original value: " + origValue : ""}
                    >
                        <span className="text-orange-700 font-semibold">
                            {ei.data[polluOrFlag]}
                        </span>
                        {origValue !== null && (
                            <span className="ml-2 text-xs text-gray-700">
                                ({origValue})
                            </span>
                        )}
                    </div>
                ) : (
                    data[polluOrFlag]
                )}
            </td>
        );
    };

    const getCurrentValue = (data, polluOrFlag) => {
        const time = data[TIME];
        const ei = editedInterims[time];
        const edited = ei && ei.edited[polluOrFlag];
        const currValue = edited ? ei.data[polluOrFlag] : data[polluOrFlag];
        return currValue !== null && currValue !== "" ? currValue : null;
    };

    const updateSortBy = (field) => {
        let newSortBy = Object.assign({}, sortBy);
        if (field === newSortBy.field) {
            newSortBy.ascending = !newSortBy.ascending;

            // reverse selected cell when perform sorting with the same column
            reverseSelectedMinMax();
        } else {
            newSortBy.field = field;
            newSortBy.ascending = true;

            // unselect all when perform sorting with different column
            resetSelectData();
        }
        setSortBy(newSortBy);

        // sort interim data
        let newInterims = interims.slice();
        newInterims.sort((a, b) => {
            if (field !== TIME) {
                let avalue = getCurrentValue(a, field);
                let bvalue = getCurrentValue(b, field);
                if (avalue !== null && bvalue !== null) {
                    avalue = Number(avalue);
                    bvalue = Number(bvalue);
                    // only sort different values, then sort same values by time
                    if (avalue !== bvalue) {
                        return newSortBy.ascending
                            ? avalue - bvalue
                            : bvalue - avalue;
                    }
                } else if (avalue !== null) {
                    return newSortBy.ascending ? 1 : -1;
                } else if (bvalue !== null) {
                    return newSortBy.ascending ? -1 : 1;
                }
            }
            return a[TIME] === b[TIME]
                ? 0
                : newSortBy.ascending
                ? a[TIME] > b[TIME]
                    ? 1
                    : -1
                : a[TIME] < b[TIME]
                ? 1
                : -1;
        });
        setInterims(newInterims);
    };

    const sortIndicator = (field) => {
        if (sortBy.field === field) {
            return (
                <div>
                    <Icon
                        name={sortBy.ascending ? SortUp : SortDown}
                        width="16px"
                    />
                </div>
            );
        }
        return null;
    };

    const recordTable = () => (
        <table className="admin-table select-none w-3/4">
            <thead>
                <tr>
                    <th style={{ width: "13%" }}>
                        <div
                            onClick={() => {
                                updateSortBy(TIME);
                            }}
                        >
                            Time (HKT)
                            {sortIndicator(TIME)}
                        </div>
                    </th>
                    <th style={{ width: "15%" }}>
                        <div
                            onClick={() => {
                                updateSortBy(NO_CONC);
                            }}
                        >
                            NO (ppb)
                            {sortIndicator(NO_CONC)}
                        </div>
                    </th>
                    <th style={{ width: "14%" }}>NO Flag</th>
                    <th style={{ width: "15%" }}>
                        <div
                            onClick={() => {
                                updateSortBy(NO2_CONC);
                            }}
                        >
                            NO2 (ppb)
                            {sortIndicator(NO2_CONC)}
                        </div>
                    </th>
                    <th style={{ width: "14%" }}>NO2 Flag</th>
                    <th style={{ width: "15%" }}>
                        <div
                            onClick={() => {
                                updateSortBy(PM25_CONC);
                            }}
                        >
                            PM2.5 (μg/m³)
                            {sortIndicator(PM25_CONC)}
                        </div>
                    </th>
                    <th style={{ width: "14%" }}>PM2.5 Flag</th>
                </tr>
            </thead>
            <tbody>
                {interims.map((data, idx) => (
                    <tr key={idx}>
                        <td>
                            {getDiffHourMinuteText(
                                data[TIME],
                                momentTz(
                                    displayDate,
                                    timeFormats.YYYYMMDD
                                ).toDate()
                            )}
                        </td>
                        {polluOrFlagTD(idx, data, NO_CONC)}
                        {polluOrFlagTD(idx, data, NO_FLAG)}
                        {polluOrFlagTD(idx, data, NO2_CONC)}
                        {polluOrFlagTD(idx, data, NO2_FLAG)}
                        {polluOrFlagTD(idx, data, PM25_CONC)}
                        {polluOrFlagTD(idx, data, PM25_FLAG)}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const OptionPanel = () => (
        <div className="flexrow sticky top-0 pt-2 bg-white z-50">
            <div className="w-2/3 flexcol">
                <div className="flexrow items-center">
                    <select
                        className="input"
                        style={{ width: "400px" }}
                        id="lamppost"
                        type="text"
                        value={curLamppost}
                        onChange={onChangeLamppost}
                        disabled={loading}
                    >
                        {lampposts.map((l, idx) => {
                            return (
                                <option key={idx} value={l.lamppost_id}>
                                    {lamppostDisplayName(l)}
                                </option>
                            );
                        })}
                    </select>
                    <button
                        className="ml-8 pl-1 gray"
                        title="Previous date"
                        onClick={() => {
                            let prev = addDate(day, -1, "d");
                            if (prev) {
                                onChangeDay(prev);
                            }
                        }}
                        disabled={loading}
                    >
                        <Icon name={CaretLeft} width="1.5rem" />
                    </button>
                    <DefDatePicker
                        selected={day}
                        onChange={onChangeDay}
                        disabled={loading}
                    />
                    <button
                        className="pr-1 gray"
                        title="Next date"
                        onClick={() => {
                            let next = addDate(day, 1, "d");
                            if (next) {
                                onChangeDay(next);
                            }
                        }}
                        disabled={loading}
                    >
                        <Icon name={CaretRight} width="1.5rem" />
                    </button>
                </div>
                <div className={"ml-4 mt-3" + (loading ? " hidden" : "")}>
                    <span className="label text-base">Last validated by</span>
                    {lastValidated.by
                        ? lastValidated.by +
                          "  (at " +
                          formatDate(lastValidated.at, timeFormats.dayTime) +
                          ")"
                        : "(No record)"}
                </div>
            </div>
            <div className="w-1/3 text-right">
                {canEdit() && (
                    <button
                        className="btn-primary w-1/3"
                        disabled={editedTotal() === 0}
                        onClick={() => setConfirm(true)}
                    >
                        Save
                    </button>
                )}
                {canReject() && user && lampposts.length !== 0 && (
                    <RejectView
                        date={dateQp}
                        lamppostService={lamppostService}
                        lamppost={lampposts.find(
                            (l) => l.lamppost_id === curLamppost
                        )}
                        adminName={user.profile.username}
                        onRejectErr={onRejectErr}
                    />
                )}
            </div>
        </div>
    );

    const editPanel = () => (
        <>
            <SuccessMsg
                msg={
                    saveState.ok
                        ? "Successfully save edited interim records."
                        : ""
                }
            />
            <ErrMsg msg={saveState.error} />
            <ErrMsg msg={unlockErr} />

            <FlagDescTables visible={!selectStart.polluOrFlag} />

            {editPollutant()}
            {editPollutFlag()}
            {updating && (
                <Loading cssClass="text-center mt-4" word="Updating" />
            )}
            {updateErr && (
                <div className="text-err">
                    Please select another lamppost or date:
                    <br />
                    {updateErr}
                </div>
            )}
        </>
    );

    return (
        <>
            <div className="title-h1 flex items-center">
                <Link
                    className="inline-block btn-link pr-2"
                    title="Return to page 'Data Validation Overview'"
                    to="/validation"
                    onClick={(e) => {
                        e.preventDefault();
                        if (isEditingValidation()) {
                            setConfirmUnsaveReturn(true);
                        } else {
                            navigate("/validation");
                        }
                    }}
                >
                    <Icon name={ChevronLeft} width="1.5rem" />
                </Link>
                Data Validation
            </div>
            {lampposts.length !== 0 && <OptionPanel />}

            {confirmUnsaveReturn && (
                <ConfirmBox
                    action={() =>
                        unlockAndAction(lamppostService, handleAuthError, () =>
                            navigate("/validation")
                        )
                    }
                    cancelAction={() => setConfirmUnsaveReturn(false)}
                    labelOk="Discard changes"
                    deleteBtn={true}
                    savingTitle="Please wait"
                >
                    Are you sure to leave the page{" "}
                    <span className="text-red-700">
                        without saving edited records
                    </span>
                    ?
                </ConfirmBox>
            )}

            {confirm && (
                <ConfirmBox
                    action={saveInterim}
                    cancelAction={() => setConfirm(false)}
                >
                    Are you sure to save{" "}
                    <span className="font-semibold">
                        {editedTotal()} edited interim records
                    </span>
                    ?
                </ConfirmBox>
            )}

            {confirmUnsave && (
                <ConfirmBox
                    action={() => getInterimData(curLamppost, day)}
                    cancelAction={() => {
                        setCurLamppost(prevOption.lamppostId);
                        setDayCheckReject(prevOption.day);
                        setConfirmUnsave(false);
                        setLamppostIdQp(prevOption.lamppostId);
                    }}
                    labelOk="Discard changes"
                    deleteBtn={true}
                    savingTitle="In progress"
                >
                    Are you sure to change options{" "}
                    <span className="text-red-700">
                        without saving edited records
                    </span>
                    ?
                </ConfirmBox>
            )}

            {loading ? (
                <Loading />
            ) : interims.length > 0 ? (
                <div className="mt-3 flex flex-row">
                    {recordTable()}
                    <div className="w-1/4 pl-6 relative -mt-4">
                        <div className="sticky pt-4" style={{ top: "60px" }}>
                            {canEdit() ? (
                                editPanel()
                            ) : (
                                <>
                                    <div className="gray mb-4">
                                        Approving data cannot be edited.
                                    </div>
                                    <FlagDescTables visible={true} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center py-32">No data</div>
            )}
        </>
    );
};

DataValidate.propTypes = {
    setRefreshSessionCallback: PropTypes.func.isRequired,
    forceCheckSession: PropTypes.func.isRequired
};

const RejectView = ({
    date,
    lamppostService,
    lamppost,
    adminName,
    onRejectErr
}) => {
    const [actionErr, setActionErr] = useState("");
    const [confirmReject, setConfirmReject] = useState(false);
    const formattedDate = formatDateStr(
        date,
        timeFormats.YYYYMMDD,
        timeFormats.day
    );

    const rejectDataset = () => {
        lamppostService
            .rejectMetricDataset(
                lamppost && lamppost.lamppost_id,
                formatDateStr(date, timeFormats.YYYYMMDD, timeFormats.YYYYMM),
                `Reject '${formattedDate}' data (By ${adminName})`
            )
            .then(() => navigate("/validation"))
            .catch((err) => {
                const errMsg = err.message || "Cannot reject dataset.";
                setActionErr(errMsg);
                setConfirmReject(false);
                onRejectErr(err);
            });
    };

    return (
        <>
            <button
                className="btn-delete w-1/3 py2"
                onClick={() => {
                    setActionErr("");
                    setConfirmReject(true);
                }}
            >
                Reject Validation
            </button>
            <ErrMsg msg={actionErr} />

            {confirmReject && (
                <ConfirmBox
                    action={rejectDataset}
                    cancelAction={() => setConfirmReject(false)}
                    deleteBtn={true}
                >
                    <div className="text-left">
                        Are you sure to{" "}
                        <span className="text-red-700">reject the dataset</span>{" "}
                        for lammpost
                        {" " + lamppostDisplayName(lamppost)} on month '
                        {formatDateStr(
                            date,
                            timeFormats.YYYYMMDD,
                            timeFormats.month
                        )}
                        ' by rejecting '{formattedDate}' data?
                    </div>
                </ConfirmBox>
            )}
        </>
    );
};

const getDiffHourMinuteText = (dtxt, originTime) => {
    let diffMinutes = getDiffMinutes(new Date(dtxt), originTime);
    let h = Math.floor(diffMinutes / 60);
    let m = diffMinutes - h * 60;
    return `${("" + h).padStart(2, 0)}:${("" + m).padStart(2, 0)}`;
};

const toYYYYMMDD = (date) => formatDate(date, "YYYYMMDD");

const { TIME, NO_CONC, NO_FLAG, NO2_CONC, NO2_FLAG, PM25_CONC, PM25_FLAG } = {
    TIME: "observation_time",
    NO_CONC: "no_conc",
    NO_FLAG: "no_flag",
    NO2_CONC: "no2_conc",
    NO2_FLAG: "no2_flag",
    PM25_CONC: "pm25_conc",
    PM25_FLAG: "pm25_flag"
};

const isPollutant = (polluOrFlag) =>
    polluOrFlag === NO_CONC ||
    polluOrFlag === NO2_CONC ||
    polluOrFlag === PM25_CONC;

const isPolluFlag = (polluOrFlag) =>
    polluOrFlag === NO_FLAG ||
    polluOrFlag === NO2_FLAG ||
    polluOrFlag === PM25_FLAG;

const polluForFlag = (flag) =>
    flag === NO_FLAG
        ? NO_CONC
        : flag === NO2_FLAG
        ? NO2_CONC
        : flag === PM25_FLAG
        ? PM25_CONC
        : null;

const getPolluNames = () => {
    let names = {};
    names[NO_CONC] = "NO";
    names[NO_FLAG] = "NO Flag";
    names[NO2_CONC] = "NO2";
    names[NO2_FLAG] = "NO2 Flag";
    names[PM25_CONC] = "PM2.5";
    names[PM25_FLAG] = "PM2.5 Flag";
    return names;
};
const POLLU_NAMES = getPolluNames();

const setPolluForFlag = (flagVal) =>
    flagVal === "c" ? { value: 0, ok: true } : { ok: false };

const POLLU_FLAGS = [
    { desc: "No flag", value: "" },
    {
        desc: "No data from lamppost",
        value: "a"
    },
    {
        desc: "Sensor malfunction but somehow it sent out data as scheduled",
        value: "b"
    },
    {
        desc: "Data below detection limit",
        value: "c"
    },
    {
        desc: "Data below buffer zone",
        value: "d"
    },
    {
        desc: "Any data that are found abnormal after inter-lamppost check",
        value: "e"
    },
    {
        desc:
            "Unusual activity near the sensor that made the measurements unrepresentative",
        value: "f"
    },
    {
        desc:
            "Sensor re-calibration that lead to revision in some historical data",
        value: "g"
    }
];

const FlagDescTables = ({ visible }) => (
    <div className={visible ? "" : "hidden"}>
        <div className="label mb-2">Meaning of NO/NO2/PM2.5 Flags:</div>
        {POLLU_FLAGS.map(
            (pf) =>
                pf.value && (
                    <div
                        key={"f_" + pf.value}
                        className="flex flex-row items-baseline py-1 border-b border-gray-200"
                    >
                        <div className="w-1/12 label font-semibold">
                            {pf.value}
                        </div>
                        <div className="w-11/12 text-xs">{pf.desc}</div>
                    </div>
                )
        )}
    </div>
);

FlagDescTables.propTypes = {
    visible: PropTypes.bool.isRequired
};
